import {
  decodeCheckoutData,
  decodeStatusData,
  extractIntegrationData,
  parseAppSectionParams,
  extractRestrictedPageParams,
} from '@wix/pricing-plans-router-utils';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { SubPage } from '../../../types/common';
import { withMonitoring } from '../../../utils/decorators';
import { isDayful } from '../../../utils/is-dayful';
import { getSectionId, getSubPagePath } from '../../../utils/navigation';

export class Navigation {
  constructor(
    protected flowAPI: ControllerParams['flowAPI'],
    protected appDefinitionId: string,
    protected readonly hasMultiplePages: boolean,
  ) {}

  get wixCodeApi() {
    return this.flowAPI.controllerConfig.wixCodeApi;
  }

  get isMultiPageApp() {
    return this.hasMultiplePages;
  }

  async setCurrentPath(subPage: SubPage) {
    const { appDefinitionId, isMultiPageApp } = this;
    const sectionId = getSectionId(subPage.name, { isMultiPageApp });
    const path = getSubPagePath(subPage, { isMultiPageApp });
    const { relativeUrl } = await this.wixCodeApi.site.getSectionUrl({ appDefinitionId, sectionId });
    this.wixCodeApi.location.to!((relativeUrl ?? '') + path, { disableScrollToTop: true });
  }

  getCurrentSubPage = withMonitoring(this.flowAPI, async (): Promise<SubPage> => {
    const { location } = this.wixCodeApi;
    const { path } = location;
    if (path[1] === 'payment') {
      return { name: 'checkout', checkoutData: decodeCheckoutData(path[2]) };
    } else if (path[1] === 'status') {
      return {
        name: 'status',
        statusData: decodeStatusData(path[2]),
      };
    } else {
      const params = parseAppSectionParams(location.query?.appSectionParams);
      const restrictedPageParams = extractRestrictedPageParams(params);
      const integrationData = extractIntegrationData(params);
      if (restrictedPageParams) {
        return { name: 'restricted', restrictedPageParams, integrationData };
      }
      return {
        name: 'list',
        integrationData: (await isDayful(this.flowAPI, this.wixCodeApi))
          ? { ...integrationData, navigateTo: location.baseUrl + '/_api/dayful/sch/route' }
          : integrationData,
      };
    }
  });
}
